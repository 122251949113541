import styled, { keyframes } from 'styled-components';
import Button from '../button';

const fadeIn = keyframes`
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
`;

const fadeOut = keyframes`
  0% {
    opacity: 1;
  }

  90% {
    opacity: 0;
  }

  100% {
    display: none;
  }
`;

export const Wrapper = styled.div`
  position: fixed;
  bottom: 30px;
  left: 10px;
  right: 10px;
  padding: 10px;
  border-radius: 10px;
  background: ${({ theme }:any) => theme.primary};
  color:  ${({ theme }:any) => theme.background};
  z-index: 999999;
  opacity: 0;
  box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
  animation: ${({ isClose }: any) => isClose ? fadeOut : fadeIn} 0.5s ;
  transition-timing-function: cubic-bezier(0.42,0,0.58,1);
  animation-iteration-count: 1;
  animation-fill-mode: forwards;


  &::after {
    content: '';
    display: ${({ $isIOS }: any) => $isIOS ? 'block' : 'none'};
    position: absolute;
    width: 0;
    height: 0;
    left: 50%;
    transform: translate(-50%, 0);
    border-left: 20px solid transparent;
    border-right: 20px solid transparent;
    border-top: 30px solid ${({ theme }:any) => theme.primary};
  }
`;



export const CloseButton = styled.div`
  ${({ theme: { shades } }: any) => `
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    width: 50px;
    height: 50px;
    background-color: transprent;

    &::after {
      content: '';
      display: block;
      position: absolute;
      width: 10px;
      height: 3px;
      right: 40%;
      top: 40%;
      transform: translate(60%, -60%) rotate(45deg);
      background-color: ${shades[0]};
    }

    &::before {
      content: '';
      display: block;
      position: absolute;
      width: 10px;
      height: 3px;
      right: 40%;
      top: 40%;
      transform: translate(60%, -60%) rotate(-45deg);
      background-color: ${shades[0]};
    }
  `}
`;

export const ShareIcon = styled.div`
  display: block;
  width: 40px;
  height: 40px;
  background-color: ${({ theme }:any) => theme.shades[0]};
  -webkit-mask-image: url('/images/png/share.png');
  mask-image: url('/images/png/share.png');
  mask-size: cover;
  -webkit-mask-position: center center;
  mask-position: center center;
  -webkit-mask-repeat: no-repeat;
  mask-repeat: no-repeat;
`;

export const Text = styled.p`
margin: 10px;
font-size: 18px;
line-height: 28px;
letter-spacing: 0.5px;
text-align: center;
`;

export const StyledButton = styled(Button)`
display: inline-block;
width: auto;
${({ $isInstall, theme }:any) => ($isInstall ? `
background-color: ${theme.background};
color: ${theme.primary};
` : 'opacity: 0.7;')};
margin-left: 12px;
padding: 0 24px;
font-weight: normal;
font-size: 16px;
`;

export const Actions = styled.div`
float: right;
margin: 10px;
`;

export const ActionsIOS = styled.div`
  display: flex;
  justify-content: center;
  ${Text} {
    display: flex;
    align-items: center;
    font-size: 16px;
  }
`;
